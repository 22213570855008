import Swiper from 'swiper';
import { Pagination, EffectCoverflow } from 'swiper/modules';
import estateApi from './apis/eastate-api'

/**
 * Initializes the swiper for a Featured Estate view model.
 * @returns void
 */
const initSwiper = (): void => {
    let regionPicker: HTMLElement = document.querySelector('[data-featured-estate-region-selector]');
    const swiperSelector = '[data-featured-estate-image-slider]';

    if (!regionPicker)
        return;

    let sliderElement: HTMLElement = document.querySelector(swiperSelector);
    if (sliderElement)
        createSwiper(sliderElement);

    regionPicker.addEventListener("change", (event) => {
        const target = event.target as HTMLSelectElement;
        const region = target.value;
        const estateWrapper: HTMLElement = document.querySelector('[data-featured-estate-wrapper]');

        estateApi.getEstateFromRegion(region)
            .then(html => {
                estateWrapper.innerHTML = html;
                let swiper: HTMLElement = document.querySelector(swiperSelector);
                createSwiper(swiper);
            });
    });
}

/**
 * Used to create a swiper from a htmlelement.
 * @param sliderElement The element that has a swiper in it to initialize.
 * @returns The swiper element.
 */
const createSwiper = (sliderElement: HTMLElement): Swiper => {
    let swiperCount = sliderElement.getElementsByClassName('swiper-slide')?.length;

    return new Swiper(sliderElement, {
        modules: [Pagination, EffectCoverflow],
        effect: 'coverflow',
        loop: (swiperCount <= 2) ? false : true,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        breakpoints: {
            640: {
                loop: (swiperCount <= 4) ? false : true
            }
        },
        coverflowEffect: {
            rotate: 0,
            stretch: 100,
            depth: 300,
            modifier: 1,
            slideShadows: true,
        },
        pagination: {
            el: '.swiper-pagination',
        },
    });
}

export default {
    initSwiper
}
