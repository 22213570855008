export class CloseFilter {
    static init() {
        console.debug('Initializing CloseFilter...');

        let filterTab = document.getElementById("filter-tab");
        if (!filterTab) {
            return;
        }

        let filterTabPane = document.getElementById("filter-tab-pane");
        if (!filterTabPane) {
            return;
        }

        let areaDropdown = document.getElementById("area-dropdown") as HTMLSelectElement;
        if (!areaDropdown) {
            return;
        }

        let serieDropdown = document.getElementById("serie-dropdown") as HTMLSelectElement;
        if (!serieDropdown) {
            return;
        }

        let clubDropdown = document.getElementById("club-dropdown") as HTMLSelectElement;
        if (!clubDropdown) {
            return;
        }

        let closeButton = document.getElementById("close-filter-button");
        if (closeButton) {
            closeButton.addEventListener("click", function() {
                filterTabPane.classList.remove("active");
                filterTabPane.classList.remove("show");
                filterTab.classList.remove("active");
            });
        }

        let resetButton = document.getElementById("reset-filter-button");
        if (resetButton) {
            resetButton.addEventListener("click", function () {
                window.location.href = "/";
            });
        }
    }
}