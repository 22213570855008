import * as $ from 'jquery';
import { Notifications } from './notifications';
import { PaginationModule } from './pagination';

export class Section {
    private static page = 0;
    private static readonly paginationModule = new PaginationModule();

    static init(): void {
        let sectionExcludeIds = [];

        $(() => {
            let params = new URLSearchParams(window.location.search);
            params.delete('setRegion');
            let queryStr = params.toString();
            if (queryStr.endsWith("=", queryStr.length))
                queryStr = queryStr.slice(0, queryStr.length - 1);
            history.replaceState(null, null, window.location.href.split('?')[0] + (queryStr ? '?' + queryStr : ''));
            sectionExcludeIds = [];
            document.querySelectorAll("[data-article-id]").forEach((e) => {
                sectionExcludeIds.push(e.getAttribute("data-article-id"));
            });
        });

        $('#filter-btn').click(() => {
            const btn = $('#filter-btn');
            btn.toggleClass('active').next().slideToggle();
        });

        /*
         * Fetch another page of results (section pages).
         */
        $('#btn-section-fetch-more').click(() => {
            this.page++;
            const btn = $('#btn-section-fetch-more');
            // disable the load more button and change text to show user we are loading.
            btn.prop('disabled', true);
            btn.text('Henter...');

            let sectiontype = $('.row[data-section-type]:last').data('section-type');

            $.ajax({
                type: "POST",
                url: '/api/section',
                data: JSON.stringify({
                    page: this.page,
                    excludeIds: sectionExcludeIds,
                    type: "" + sectiontype
                }),
                contentType: 'application/json',
                cache: false
            }).done((data) => {
                this.paginationModule.sectionLoadMore(data);

                // push the page to history
                this.paginationModule.addPageToHistory();

                // if there are more articles aviable enable load more button; otherwise hide it.
                if (data.morePages === true) {
                    btn.prop('disabled', false);
                    btn.text('Hent flere');
                } else {
                    btn.hide();
                }
            }).fail(() => {
                // An error occurred, show a message to the user and enable the button.
                Notifications.addNotification('Fejl', 'Det var ikke muligt at henter flere artikler, prøv igen.', `toast-${this.page}`);
                btn.prop('disabled', false);
                btn.text('Hent flere');
            });
        });

        /*
         * Fetch another page of results (front page section)
         */
        $('#btn-frontpage-fetch-more').click(() => {
            this.page++;
            const btn = $('#btn-frontpage-fetch-more');
            // disable the load more button and change text to show user we are loading.
            btn.prop('disabled', true);
            btn.text('Henter...');

            $.ajax({
                type: "POST",
                url: '/api/frontpage',
                data: JSON.stringify({
                    page: this.page,
                    excludeIds: sectionExcludeIds,
                    carouselType: "",
                    currentUrl: window.location.href
                }),
                contentType: 'application/json',
                cache: false
            }).done((data) => {
                this.paginationModule.frontpageLoadMore(data);

                $('.load-more-content').data('last-timestamp', data.lastTimestampTicks);

                // push the page to history
                this.paginationModule.addPageToHistory();

                // if there are more articles aviable enable load more button; otherwise hide it.
                if (data.morePages === true) {
                    btn.prop('disabled', false);
                    btn.text('Hent flere');
                } else {
                    btn.hide();
                }
            }).fail(() => {
                // An error occurred, show a message to the user and enable the button.
                Notifications.addNotification('Fejl', 'Det var ikke muligt at henter flere artikler, prøv igen.', `toast-${this.page}`);
                btn.prop('disabled', false);
                btn.text('Hent flere');
            });
        });

        $('#btn-theme-fetch-more').click(() => {
            const btn = $('#btn-theme-fetch-more');
            // disable the load more button and change text to show user we are loading.
            btn.prop('disabled', true);
            btn.text('Henter...');
            this.page++;
            $.ajax({
                type: "POST",
                url: '/api/theme/getthemepages',
                data: JSON.stringify({
                    page: this.page,
                    excludeIds: sectionExcludeIds,
                    type: 'FrontpageExceptGuide',
                    tag: btn.attr('data-theme'),
                    currentUrl: window.location.href
                }),
                contentType: 'application/json',
                cache: false
            }).done((data) => {
                this.paginationModule.sectionLoadMoreThemeSection(data);
                this.page++;
                // push the page to history
                this.paginationModule.addPageToHistory();

                // if there are more articles aviable enable load more button; otherwise hide it.
                if (data.morePages) {
                    btn.prop('disabled', false);
                    btn.text('Hent flere');
                } else {
                    btn.hide();
                }
            }).fail(() => {
                // An error occurred, show a message to the user and enable the button.
                Notifications.addNotification('Fejl', 'Det var ikke muligt at henter flere artikler, prøv igen.', `toast-${this.page}`);
                btn.prop('disabled', false);
                btn.text('Hent flere');
            });
        });
    }
}
