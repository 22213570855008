export class Recommendation {

    static init(): void {
        this.getMostRead();
    }

    public static getMostRead(): void {
        $.ajax({
            type: "GET",
            url: `/api/recommendation/getmostread`,
            contentType: 'application/json',
        }).done((data) => {
            let mostReadContainer = $('div[data-most-read-container]');
            mostReadContainer.append(data);
        }).fail(() => {
            console.log("Error fetching MostRead data.")
        });
    }
}