const getUrlTargeting = function (): any {
  const urlMatches = window.location.toString().match(/^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/);
  const matchedAuthority = urlMatches[4] || '';
  const matchedPath = (urlMatches[5] || '').replace(/(.)\/$/, '$1');
  const matchedQuery = urlMatches[7] || '';

  const params = matchedQuery.replace(/\=/ig, ':').split('&');

  return {
    'UrlHost': matchedAuthority,
    'UrlPath': matchedPath,
    'UrlQuery': params
  };
}

const init = function () {
  // notify GAM of the section we are currently loading banners for
  window.googletag = window.googletag || { cmd: [] };

  googletag.cmd.push(function () {
    //googletag.pubads().collapseEmptyDivs();
    googletag.pubads().enableSingleRequest();

    const targeting = getUrlTargeting();
    for (const key in targeting) {
      if (targeting.hasOwnProperty(key)) {
        const value = targeting[key];

        googletag.pubads().setTargeting(key, value);
      }
    }

    if (window.sektionKeyValue) {
      googletag.pubads().setTargeting("Sektion", window.sektionKeyValue);
    }

    if (window.lokalsiteKeyValue) {
      googletag.pubads().setTargeting("Lokalsite", window.lokalsiteKeyValue);
    }
  });
}

/** Exports the KeyValuePair functionality as a library used in app.ts */
export default {
  init
};
