export class ShoppingStoreToggle {
    /**
     * Articles to show when pressing show more.
     * */
    static showMoreCount: number = 18;

    /**
     * Observes changes to news items.
     */
    static mutationObserver: MutationObserver;

    /**
     * Initializes the ShoppingStoreToggle component.
     */
    static init(): void {
        console.debug('Initializing ShoppingStoreToggle');
    
        if(document.querySelector("[data-shopping-store-list]"))
        {
            this.initMutationObserver();
            this.bindShowMore();
        }
    }

    static initMutationObserver(): void {
        const callback: MutationCallback = (mutations, observer) => {
            let newsList = $("[data-shopping-store-list]");
            let toggleButton = $('[data-show-more="shopping-list"]');
            if (toggleButton) {
                let hiddenNews = newsList.find('div[data-show="0"]');
                if (hiddenNews && hiddenNews.length > 0) {
                    toggleButton.show();
                } else {
                    toggleButton.hide();
                }
            }
        }

        this.mutationObserver = new MutationObserver(callback);

        const mutationConfig: MutationObserverInit = {
            childList: true,
            subtree: true,
            attributes: true,
            characterData: false,
            attributeOldValue: false,
            characterDataOldValue: false
        };
        
        this.mutationObserver.observe(document.querySelector("[data-shopping-store-list]"), mutationConfig);
    }

    static bindShowMore(): void {
        $('[data-show-more="shopping-list"]')
            .on('click', (event: JQuery.ClickEvent) => {
                event.preventDefault();
                $("[data-shopping-store-list]")
                    .find('div[data-show="0"]')
                    .slice(0, this.showMoreCount)
                    .attr('data-show', '1');
            });
    }
}