export class Tip {
    private static fileArray: Array<File> = [];

    static init() {
        $('#file-upload-btn').on('click', () => this.openUploadUi());
        $('#image-upload-fld').on('change', (event: JQueryEventObject) => this.handleFileUploadStatusChanged(event));
    }

    /**
     * Open the file upload UI.
     */
    private static openUploadUi() {
        $('#image-upload-fld').click();
    }

    /**
     * Handle file upload event.
     * @param e click event
     */
    private static handleFileUploadStatusChanged(e): void {
        const htmlInputElement: HTMLInputElement = e.currentTarget as HTMLInputElement;

        for (let i = 0; i < htmlInputElement.files.length; i++) {
            this.fileArray.push(htmlInputElement.files[i]);
        };

        this.updateFilesHtml();
    }

    /**
    * Update files html
    */
    private static updateFilesHtml(): void {
        let filesHtml = "";

        $.each(this.fileArray, (key: any, item: File) => {
            filesHtml += "<span class='file' data-index='" + key + "'>" + item.name + "<img class='trash-icon' src='/content/images/icons/trash.svg' alt='Luk' /></span>"
        });

        $(".upload-files").html(filesHtml);

        $(".upload-files").unbind("click");
        $('.upload-files').on('click', '.trash-icon', (event: JQueryEventObject) => this.removeFile(event));
    }

    /**
    * Handle remove file event.
    */
    private static removeFile(event: JQueryEventObject): void {
        const fileElement: JQuery<Element> = $(event.currentTarget.closest(".file"));
        const fileIndex: number = fileElement.data("index");

        this.fileArray.splice(fileIndex, 1);
        fileElement.remove();

        this.updateFilesHtml();
    }
}