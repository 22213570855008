import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

export class articleSwiper {

  static init(): void {
    console.debug("Initializing articleSwiper...");

    const ele = document.querySelectorAll(".most-read-teaser");

    ele.forEach((element: HTMLElement) => {
      const swiperElement: HTMLElement = element.querySelector('.most-read-teaser-swiper');
      const nextBtn: HTMLElement = element.querySelector('.most-read-teaser__swiper-button-next');
      const prevBtn: HTMLElement = element.querySelector('.most-read-teaser__swiper-button-prev');

      // Init all carousels on the page.
      const swiper = new Swiper(swiperElement, {
        modules: [Pagination, Navigation],
        height: 300,
        slidesPerView: 1.5,
        breakpoints: {
          1280: {
            slidesPerView: 2.5,
          }
        },
        spaceBetween: 16,
        navigation: {
          nextEl: nextBtn,
          prevEl: prevBtn,
        }
      });

    });
  }
}
