const requestInit: RequestInit = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: new Headers(),
}

const getEstateFromRegion = function (region: string): Promise<string> {
    return fetch(`/api/Estate/featuredEstate?region=${region}`, requestInit)
        .then(response => response.text())
}

export default {
    getEstateFromRegion
}
