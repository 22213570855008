import { Toast } from 'bootstrap';

export class Offer {

    static init() {

        const toast = document.querySelector("#confirmationToast") as HTMLElement;
        if (toast != null) {
            const showUserExists = toast.dataset.showuserexists.toLowerCase()

            const showConfirmation = toast.dataset.showconfirmation.toLowerCase()

            if (showConfirmation === "true" || showUserExists === "true") {
                var text = showConfirmation === "true" ? "Du er nu tilmeldt" : "Du er allerede tilmeldt";

                const message = document.getElementById('toastmessage')
                message.innerText = text;
                const toastLiveExample = document.getElementById('confirmationToast')
                window.onload = (event) => {
                    const toast = new Toast(toastLiveExample)
                    toast.show();
                }
            }
        }
    }
}
