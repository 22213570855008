import * as $ from 'jquery';
import { Search } from '../search'
import { Section } from '../section'
import * as Bricklayer from 'bricklayer';

export class TeamFilter {
    static init() {

        console.debug('Initializing TeamFilter component');

        $("#area-dropdown").on("change",  this.OnTeamSelectorChanged);
        $("#serie-dropdown").on("change", this.OnTeamSelectorChanged);
        $("#club-dropdown").on("change", this.OnTeamSelectorChanged);
    }

    // When changing the value in filter calls ajax function to update view
    static OnTeamSelectorChanged(): void {
        var areaValue = $("#area-dropdown").val()
        var seriesValue = $("#serie-dropdown").val();
        var clubValue = $("#club-dropdown").val();

        var tags = "";

        tags += (seriesValue) ? seriesValue + "," : "";
        tags += (clubValue) ? clubValue + "," : "";

        // Remove trailing comma(",")
        tags = tags.replace(/,+$/, "");

        TeamFilter.SearchForContent(tags, (areaValue) ? areaValue.toString() : "");
      
    }

    // Makes an ajax call to search for the filter set
    // and then replaces the current view with the returned view
    // when done it then styles the view
    static SearchForContent(tags: string, _location: string):void {
        $.ajax({
            type: "GET",
            url: '/filtersearch',
            data: {
                query: "",
                tags: tags,
                location: _location,
                page: 0,
                currentUrl: window.location.href
            },
            contentType: 'application/json',
            cache: false
        }).done((request) => {
            var frontpage = $(".frontpage");
            var searchResults = $("#search-result-container");
            if (frontpage[0] != undefined) {
                frontpage[0].outerHTML = request;
            }else if (searchResults[0] != undefined) {
                searchResults[0].outerHTML = request;
            }
        }).then(() => {
            Search.init();
            Section.init();
            document.querySelectorAll('.bricklayer').forEach(function (section) {
                new Bricklayer(section);
            });
        });
    }
}