export class HeaderScroll {
    static init() {

        let navbar_height = document.querySelector<HTMLElement>('.page-header').offsetHeight;
        var last_scroll_top = window.scrollY;

        window.addEventListener('scroll', function () {
            let scrolledY = window.scrollY;
            let el_autohide = document.querySelector('.page-header');

            if (scrolledY > last_scroll_top && scrolledY >= navbar_height) {
                el_autohide.classList.remove('page-header--scrolled-up');
                el_autohide.classList.add('page-header--scrolled-down');
            } else {
                el_autohide.classList.remove('page-header--scrolled-down');
                el_autohide.classList.add('page-header--scrolled-up');
            }

            last_scroll_top = scrolledY;
        });
    }
}
