import * as $ from 'jquery';
import Swiper from 'swiper';
import { Pagination, Navigation } from 'swiper/modules';

export class Carousel {
  private static sliders = [];

  static init() {
    const _this = this;

    // Init all carousels on the page.
    $('.swiper-container').each(function (index: number, element: HTMLElement) {
      const slider = new Swiper(element, {
        modules: [Pagination, Navigation],
        slidesPerView: 1.2,
        spaceBetween: 19,
        slidesPerGroup: 1,
        loopAddBlankSlides: true,
        autoHeight: false,
        loop: true,
        updateOnWindowResize: true,
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 19,
            slidesPerGroup: 3
          }
        },
        navigation: {
          nextEl: $(element).parent().find('.swiper-button-next')[0],
          prevEl: $(element).parent().find('.swiper-button-prev')[0]
        }
      });

      _this.sliders.push(slider);
    });
  }
}
