import * as $ from 'jquery';
import * as Bricklayer from 'bricklayer';
import { Notifications } from './notifications';

export class Search {
    private static page = 0;
    private static query = '';

    static init() {
        let bl;

        /*
         * Menu search field
         */
        $(".search-fld-wrapper .input-file-image").click(() => {
            this.performSearch(String($("#search-fld").val()));
        });

        $("#search-fld").on('keypress', (e: JQueryKeyEventObject) => {
            if (e.which === 13) {
                this.performSearch(String($("#search-fld").val()));
            }
        });

        /*
         * Search page search field
         */
        $("#search-fld-2 .input-file-image").click(() => {
            this.performSearch(String($("#search-fld-2").val()));
        });

        $("#search-fld-2").on('keypress', (e: JQueryKeyEventObject) => {
            if (e.which === 13) {
                this.performSearch(String($("#search-fld-2").val()));
            }
        });

        /*
         * 404 page search field
         */
        $("#search-fld-3 .input-file-image").click(() => {
            this.performSearch(String($("#search-fld-3").val()));
        });

        $("#search-fld-3").on('keypress', (e: JQueryKeyEventObject) => {
            if (e.which === 13) {
                this.performSearch(String($("#search-fld-3").val()));
            }
        });

        $("#search-fld-4").on('keypress', (e: JQueryKeyEventObject) => {
            if (e.which === 13) {
                this.performSearch(String($("#search-fld-4").val()));
            }
        });

        /*
         * Init search page brick layer.
         */
        document.querySelectorAll('.bricklayer-search').forEach(function (section) {
            bl = new Bricklayer(section);
        });

        /*
         * Fetch another page of results.
         */
        $('#btn-fetch-more').click(() => {
            this.page++;
            const btn = $('#btn-fetch-more');
            // disable the load more button and change text to show user we are loading.
            btn.prop('disabled', true);
            btn.text('Henter...');

            $.ajax({
                type: "POST",
                url: '/api/search',
                data: JSON.stringify({
                    query: this.getQueryParam('q'),
                    tag: this.getQueryParam('tag'),
                    page: this.page
                }),
                contentType: 'application/json',
                cache: false
            }).done((data) => {
                // Add the teasers to the bricklayer element.
                $.each(data.teasers, function (index, value) {
                    bl.append($(value)[0]);
                });

                // If more stories avaiable then enable the button the set text; otherwise hide the button.
                if (data.morePages === true) {
                    btn.prop('disabled', false);
                    btn.text('Hent flere');
                } else {
                    btn.hide();
                }
            }).fail(() => {
                // An error occurred, show a message to the user and enable the button.
                Notifications.addNotification('Fejl', 'Det var ikke muligt at henter flere artikler, prøv igen.', `toast-${this.page}`);
                btn.prop('disabled', false);
                btn.text('Hent flere');
            });
        });
    }

    /**
     * Perform search.
     * @param query Search query.
     */
    static performSearch(query: string): void {
        if (query.length > 0) {
            this.query = query;
            window.location.href = '/soeg?q=' + encodeURIComponent(query);
        }
    }

    /**
     * Get value of query string parameter.
     * @param key query parameter to get value of.
     */
    static getQueryParam(key: string): string {
        key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&");
        const match = location.search.match(new RegExp("[?&]" + key + "=([^&]+)(&|$)"));

        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    }
}